import { Box, Container, Grid } from "@mui/material";
import PageHeading from "../PageHeading";
import Spacing from "../Spacing";
import CompanyCard from "../CompanyCard";
import { CompanyData } from "../CompanyCard/CompanyData";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";
import { useEffect } from "react";

const Companies = () => {
  const theme = useThemeContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Companies in Belagavi"
        bgSrc="/images/about_hero_bg.jpeg"
        pageLinkText="Companies"
      />
      <Container
        maxWidth="xxl"
        sx={{
          backgroundColor: theme.mode ? colors.gray : colors.white,
        }}
      >
        <Spacing lg="100" md="80" />
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          columnSpacing={2}
        >
          {CompanyData.map((details, i) => (
            <Grid item xs={11} md={6} lg={5.5} sx={{ my: 1.5 }}>
              <Box
                key={i}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CompanyCard
                  image={details.image}
                  title={details.title}
                  description1={details.description1}
                  description2={details.description2}
                  description3={details.description3}
                  listItem1={details.listItem1}
                  listItem2={details.listItem2}
                  listItem3={details.listItem3}
                  website={details.website}
                  mail={details.mail}
                  mobile={details.mobile}
                  sx={details.sx}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      <Spacing lg="80" md="80" />
      </Container>
    </>
  );
};
export default Companies;

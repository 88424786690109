import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import Div from "../Div";
import { Box, Paper, Switch } from "@mui/material";
import { Icon } from "@iconify/react";
import { useThemeContext } from "../../context/AppProvider";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const theme = useThemeContext();

  // const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  const handleChange = (event) => {
    theme.setMode(event.target.checked);
  };

  const logo = theme.mode
    ? "/images/logo/betca_logo.png"
    : "/images/logo/betca_logo_poster.png";

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  });

  return (
    <header
      className={`cs-site_header cs-style1 text-uppercase ${
        variant ? variant : ""
      } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      style={{
        backgroundColor: theme.mode ? "black" : "white",
        color: theme.mode ? "white" : "black",
      }}
    >
      <Div className="cs-main_header">
        <Div className="container">
          <Div className="cs-main_header_in">
            <Div className="cs-main_header_left">
              <Link to="/">
                <img src={logo} alt="BeTCA" width={90} />
              </Link>
            </Div>
            <Div className="cs-main_header_center">
              <Div className="cs-nav cs-primary_font cs-medium">
                <ul
                  className="cs-nav_list"
                  style={{
                    display: `${mobileToggle ? "block" : "none"}`,
                    color: theme.mode ? "white" : "black",
                    backgroundColor: theme.mode ? "black" : "white",
                  }}
                >
                  <li>
                    <NavLink to="/" onClick={() => setMobileToggle(false)}>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="about" onClick={() => setMobileToggle(false)}>
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/companies"
                      onClick={() => setMobileToggle(false)}
                    >
                      Companies
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="https://jobs.betca.org/"
                      target="_blank"
                      onClick={() => setMobileToggle(false)}
                    >
                      Jobs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/contact"
                      onClick={() => setMobileToggle(false)}
                    >
                      Contact us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="https://docs.google.com/forms/d/e/1FAIpQLScwHJd0MKUYhJVZrYCdF7SS7JGfx2vHoXgMeaFadta22GGVNA/viewform"
                      target="_blank"
                      onClick={() => setMobileToggle(false)}
                    >
                      Be a Patron
                    </NavLink>
                  </li>
                  <li>
                    <Box
                      component={Paper}
                      display="flex"
                      flexDirection="row"
                      width={110}
                      sx={{
                        mt: 3,
                        p: 0.2,
                        borderRadius: "18px",
                        px: 0.5,
                        ml: 2,
                      }}
                    >
                      <form>
                        <Switch
                          checked={theme.mode}
                          onChange={handleChange}
                          value={theme.mode}
                        />
                      </form>
                      <Icon
                        icon={theme.mode ? "ph:moon-fill" : "tabler:sun-filled"}
                        color="black"
                        width="30"
                        height="30"
                        style={{ marginTop: "4px" }}
                      />
                    </Box>
                  </li>
                </ul>
                <span
                  className={
                    mobileToggle
                      ? "cs-munu_toggle cs-toggle_active"
                      : "cs-munu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </header>
  );
}

import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
// import FunFact from "../FunFact";
// import Cta from "../Cta";
// import TeamSlider from "../Slider/TeamSlider";
// import SectionHeading2 from "../SectionHeading2";
import Spacing from "../Spacing";
import { Container, Stack } from "@mui/material";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";

export default function AboutBelagavi() {
  const theme = useThemeContext();
  pageTitle("About");

  const color = theme.mode ? "white" : "black";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="Why Belagavi ?"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Why Belagavi ?"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: theme.mode ? colors.gray : colors.white }}
      >
        <Div className="container">
          <Spacing lg="150" md="80" />
          <Div className="row">
            <Div className="col-xl-11 col-lg-11">
              <SectionHeading
                title="History & Ancient Architechtures of Belagavi"
                color={color}
              >
                <Spacing lg="30" md="20" />
                <Stack spacing={2}>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    Belagavi, ancient ‘Venugrama’ (‘Bamboo Village’) is a
                    district and divisional headquarters, now renamed as
                    BelagaviThe Vadgoan and Madhavpur suburbs of Belagavi were
                    important urban centers between 400 BC and 300 AD. The
                    present city was built in the 12th century AD by the Ratta
                    dynasty who were based at nearby Saundatti. The fort of
                    Belagavi was built in 1204 by a Ratta officer named
                    Bichiraja. Within the walls of the Belagavi fort are the
                    late Chalukya style buildings, of which the Kamala Basti
                    with Neminatha idol in black stone should not be missed. The
                    masterpiece here is the ” Mukhamantapa” with a well-executed
                    lotus on its ceiling.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    In 1474, the Bahmani Sultanate, then ruling from Bidar,
                    captured the fort of Belagavi. Shortly afterward, in 1518,
                    the Bahamani sultanate splintered into five small states,
                    and Belagavi became part of the Adilshahi sultanate of
                    Bijapur. The Adilshahis reinforced the fort of Belagavi;
                    much of the existing structure dates from 1519. They built
                    the Safa Mosque which has three entrances with floral and
                    calligraphic designs. The Persian Inscription here states
                    that the mosque was built by Asad Khan, Bijapur Commander.
                    The Jamia Masjid in the fort was built by Sher Khan in
                    1585-86. There is a dargah of Khanjar Wali near it.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    In 1686, the Mughal emperor Aurangzeb overthrew the Bijapur
                    sultanate, and Belagavi passed nominally to the Mughals.
                    However, the Mughal empire went into decline after the death
                    of Aurangzeb in 1707, and his principal detractors, the
                    Maratha confederacy, took control of the area during the
                    rule of the Peshwas. In 1776, the country was overrun by
                    Hyder Ali of Mysore but was retaken by the Peshwa with
                    British assistance. In 1818, the British deposed the last
                    Peshwa and annexed his kingdom, which included Belagavi.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    Its salubrious climate, proximity to the coast, and
                    strategic position near Portuguese Goa commended it to the
                    British as a suitable location for an army training center
                    and cantonment, which it continues to be today for the
                    Indian Armed Forces, along with an air force station of the
                    Indian Air Force. Perhaps that is one of the reasons for
                    Belagavi’s sobriquet The Cradle of Infantry. Development of
                    a Rail Network for the movement of resources and later
                    troops was one of the means employed by both the East India
                    Company and the British to exert control over India.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    No story of Belagavi is complete without the mention of
                    Kittur Rani Chennamma. One of India’s first freedom
                    fighters, the queen’s heroic resistance is still
                    commemorated locally-in song and story. Her statue stands
                    sentinel over Belagavi at a prominent square. She along with
                    Sangolli Rayanna and Babasaheb was amongst the first to
                    start this movement
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    Belagavi was a major center of the freedom movement. Several
                    notable events have taken place here during the era of
                    freedom struggle, notable among them is the launch of Home
                    Rule League in 1916 by Tilak, hosting of an all India
                    Congress Session in 1924, the only session which was
                    presided over by Mahatma Gandhi and the only session held in
                    Karnataka.
                  </p>
                </Stack>
                <Spacing lg="30" md="30" />
                <Div className="cs-separator cs-accent_bg"></Div>
                <Spacing lg="25" md="40" />
              </SectionHeading>
            </Div>
            <Div className="col-xl-11 col-lg-11">
              <SectionHeading
                title="Demographics & Infrastructure"
                color={color}
              >
                <Spacing lg="30" md="20" />
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi district is located in the Northwest region of
                  Karnataka State. Till 1961, Belagavi was a part of the Bombay
                  state. With the creation of states based on the languages,
                  Belagavi was brought under Karnataka State. As of the 2001
                  Indian census, Belagavi had a population of 629,600. Belagavi
                  has an average literacy rate of 78%, higher than the national
                  average of 65%. The lingua franca of the region is mostly
                  Marathi and even the Kannada spoken here is laced with a
                  generous dose of Marathi. It is one of the oldest towns in the
                  state, lying at a distance of 502 km from Bangalore & 500 km
                  from Mumbai. The district comprises 1278 villages with an area
                  of 13,415 sq.km.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  The Belagavi district is bounded on the west and north by
                  Maharashtra state, on the east by Bagalkot district, on the
                  northeast by Bijapur district, on the southeast by Gadag
                  district, on the south by Dharawad district and Uttara Kannada
                  district, and on the southwest by the state of Goa. Belagavi
                  district consists of ten taluks namely Athani, Bailhongal,
                  Belagavi, Chikkodi, Gokak, Hukkeri, Khanapur, Raibag, Ramdurg
                  and Saundatti.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi City (13 degrees 32’N & 42’E) is a crossroad of
                  cultures due to its peculiar geographical location. Nestling
                  in the foothills of the enchanting Sahyadris (Western Ghats),
                  at an altitude of about 779m, 100km from the Arabian Sea with
                  River Markandeya flowing nearby Belagavi is unique. Its
                  natural beauty & environment are a world of contrast &
                  harmonies with swift & kaleidoscopic change in topography,
                  vegetation & climate (50″ rainfall). Its hinterland offers a
                  rich resource mix attracting people as tourists, settlers,
                  job-seekers & entrepreneurs, making it a veritable paradise or
                  a “poor man’s Switzerland”.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Visweswaraya Technological University (VTU), one of the
                  biggest Technological Universities in India is headquartered
                  in Belagavi. VTU has 158 colleges affiliated to it with Under
                  Graduate course in 27 disciplines and Post Graduate Programmes
                  in 67 disciplines. The intake of UG level is about 45000
                  students and about 7500 at the PG level.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi houses the Maratha Light Infantry Regimental Centre
                  (MLIRC). It also houses the Commando Training Wing which is a
                  part of the Infantry School, ‘Military Headquarters Of Western
                  India’, where the country’s infantry commandos are trained in
                  endurance, escape and evasion, guerrilla and commando warfare
                  techniques and to live off the land. The Commando course at
                  Belagavi is mandatory for all infantry officers. Officers of
                  other arms and services and even some foreign officers undergo
                  the course.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi has always been at the forefront of industrial growth
                  in India. With its inherent capabilities coupled with its
                  enterprising citizens, Belagavi provides the ideal choice for
                  investment opportunities. Belagavi is one of the fastest
                  growing cities with a very good Industrial Scenario in the
                  northwestern part of Karnataka. Belagavi has several large
                  industries, important among them are the INDAL Aluminium
                  Factory( Now Hindalco) and the Polyhydron Pvt. Ltd. Belagavi
                  acts as a trade centre for food grains, sugarcane, cotton,
                  tobacco, oilseed, and milk products. Industries include
                  leather, clay, pottery, soap, cotton, and precious metals. It
                  is very famous for its Powerloom Industries which provide
                  employment for many weavers. The Hydraulic Industry started
                  here was first of its kind in Belagavi District. Besides, it
                  also provides excellent opportunities for businessmen in
                  almost all sections of the Society and thus is said to be an
                  important Industrial and Business Centre.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi is the commercial hub of north Karnataka. Belagavi is
                  an important location for vegetable trading, fish, wood &
                  mining resource trading in north Karnataka. Rich deposits of
                  bauxite are found in Belagavi district, and have led to the
                  creation of the (HINDALCO) Indian Aluminium Company for
                  production of aluminum. Additionally, uranium deposits have
                  recently been found in Deshnur, a small village near Belagavi.
                  From the early 1970s, Belagavi began developing as an
                  important centre for the manufacture of heavy machine tools,
                  including the manufacture of high pressure oil hydraulics.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  Belagavi also is a strong industrial hub for Machine Shops
                  catering to Automotive Manufacturing, Specially in Crank-shaft
                  machining. The geographical location of the city is an
                  advantage since it is situated right in between Bangalore and
                  Mumbai/Pune to support the major automotive and aerospace
                  companies.
                </p>
                <p
                  className="cs-m0 cs-text-allignment"
                  style={{ color: theme.mode ? "white" : "black" }}
                >
                  A 300 acre AEQUS SEZ is setup along the Pune-Bangalore highway
                  to cater to precision engineering requirements of the global
                  aerospace, automotive and industrial verticals
                </p>

                <Spacing lg="30" md="30" />
                <Div className="cs-separator cs-accent_bg"></Div>
                <Spacing lg="25" md="40" />
              </SectionHeading>
            </Div>
          </Div>
        </Div>
      </Container>
    </>
  );
}

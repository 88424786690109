import React from "react";
import Button from "../Button";
import Spacing from "../Spacing";
import Div from "../Div";

export default function SectionHeading2({
  title,
  subtitle,
  subtitle2,
  btnLink,
  btnText,
  variant,
  children,
  color,
}) {
  return (
    <Div
      className={
        variant
          ? `cs-section_heading ${variant}`
          : `cs-section_heading cs-style1`
      }
    >
      {title != null && (
        <h3
          className="cs-section_title"
          style={{ color: color, textAlign: "center" }}
        >
          {title}
        </h3>
      )}
      {subtitle != null && (
        <h4 className="cs-section_subtitle" style={{ color: color }}>
          {subtitle}
        </h4>
      )}
      {subtitle2 != null && (
        <h4 className="cs-section_subtitle_heading2" style={{ color: color }}>
          {subtitle2}
        </h4>
      )}
      {children}
      {btnText && (
        <>
          <Spacing lg="45" md="20" />
          <Button btnLink={btnLink} btnText={btnText} />
        </>
      )}
    </Div>
  );
}

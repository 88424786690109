export const CompanyData = [
  // {
  //   image: "images/companies/agrimitra_logo.jpg",
  //   title: "agriMitra Plug-In LLP",
  //   description1: "Platform for Agriculture 4.0 & 5.0",
  //   description2: " ” Internet Agriculture “",
  //   website: "agrimitra.com",
  //   mail: "callerinfoid@gmail.com",
  //   mobile: "+91 9886060490",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/AnexGATE_Logo.jpeg",
  //   title: "AnexGate",
  //   description1:
  //     "Cybersecurity company that provides products for network security and secure connectivity.",
  //   website: "anexgate.com",
  //   mail: "info@anexgate.com",
  //   mobile: "+91  9880936126",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/Ajinkya_Logo.png",
    title: "Ajinkya Technologies",
    description1:
      "We are the bridge between business needs and the world of IT solutions.",
    description2:
      "Building Scalable Technology, that helps in Building Efficiency is the Key Goal, in Cost Effective Form.",
    description3:
      "We work with Startups, SMEs, SMBs and even Large Corporations like JSW, Tata, HDFC, IDFC, Aditya Birla etc.",
    website: "ajinkyatechnologies.in",
    // mail: "amey@ajinkyatechnologies.in",
    // mobile: "+91 7795392173",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/all_about_belgaum_logo.png",
    title: "All About Belgaum",
    description1:
      "All About Belgaum is the wonderful task of scripting the Better of Belagavi in the Best way.",
    website: "allaboutbelgaum.com",
    // mail: "uday@allaboutbelagaum.com",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/alphasure_logo.jpg",
    title: "Alphasure Accountants Pvt Ltd",
    description1:
      "We are a dedicated team of seasoned accounting professionals with a mission to deliver financial clarity to businesses. We provide end to end accounting and controller services. Our offices are located in Pune and Belagavi and we have a team of more than 40 professionals as of September 2024. Our clients are spread across multiple geographies including India, USA, Canada, UK and UAE. We cater to businesses of all sizes and industries. Our clients see us as partners, who deliver quality and continuity in our services at a reasonable cost. Our aim is to empower business owners to take informed strategic decisions. The founder of the company is a qualified Chartered Accountant and a CPA (USA).",
    website: "www.alphasure.in ",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  // {
  //   image: "images/companies/bitjini_logo.jpg",
  //   title: "Bitjini",
  //   description2:
  //     "Bitjini Apps and More is an Information Technology Solutions providing company situated in Belagavi, India. We focus on Mobile Applications, Web Applications, and a multitude of solutions that enables a business to operate more efficiently and profitably. We work closely with individuals with business ideas and startups to build their products and help them scale up, through quality, performance, and innovation, employing best-of-breed practices and technical wisdom to deliver a complete collaborative business experience.",
  //   website: "bitjini.net",
  //   mail: "contact@bitjini.net",
  //   mobile: "+91 8880374442",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/btp_logo.png",
  //   title: "Btp Infoserve Pvt Ltds",
  //   description1: "Healthcare BPO catering to the US healthcare industry.",
  //   website: " btp-i.com",
  //   mail: "btpi@btp-i.com",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/deltasys_logo.png",
  //   title: "DELTASYS E FORMING",
  //   description1:
  //     "DELTASYS E FORMING Develop and manufacture Large Format 3D printing machines. We make printers which can make parts in Polymers, Composites and Concretes.",
  //   website: "3deltasys.com",
  //   mail: "info@3deltasys.com",
  //   mobile: "+91 8050583644",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/ecobillz_logo.png",
    title: "Ecobillz Private Limited",
    description2:
      "Ecobillz is a digitization platform that leverages artificial intelligence (AI) to digitize business processes that were earlier done in paper and also introduces a superior digital experience to end-users. The platform capabilities cater to various sectors such as Hospitality, Hospitals, Automotive, Banking, Insurance, etc. Some of the modules in the platform for the hospitality sector include digital check-in, check-out, income audit, back-office automation, F&B check and table management, contactless operation, and many more.",
    website: "ecobillz.com",
    // mail: "contact@ecobillz.com",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/enerzi_logo.jpg",
    title: "ENERZI MICROWAVE SYSTEMS PRIVATE LIMITED",
    description2:
      "Enerzi Microwave Systems Pvt. Ltd. (EMSPL) was incorporated in August 2007. The company has registered with all the essential registrations to carryout business nationally and internationally. EMSPL has developed many products in the field of Microwave Heating over a period of time and has achieved expertise in this field.",
    website: "ENERZI.CO",
    // mail: "SALES@ENERZI.CO",
    // mobile: "+91 9886772784",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  // {
  //   image: "images/companies/FAAST_Logo.png",
  //   title: "FAAST Networks",
  //   description1:
  //     "Fiber And AirSpeed Technology, FAAST in short, was founded in 2015 in Belagavi due to Lack of high speed reliable Internet connectivity options in the City.",
  //   website: "faast.in",
  //   mail: "internet@faast.in",
  //   mobile: "+91 8800755655",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/FIS_New_logo.png",
  //   title: "Fortune Infoserveindia Pvt Ltd",
  //   description2:
  //     "On subscription bases, Fortune Infoserve provides cloud-hosted banking solution for SME’s who are into lending and deposit business like Credit Cooperative, Souharda, Nidhi Company, Finance Company etc. We have been empanelled by Karnataka State Souharda Federal Cooperative Ltd. and have satisfied customer from all across India and expanded in Central Asia.",
  //   website: "fortuneinfoserve.com",
  //   mail: "info@fortuneinfoserve.com",
  //   mobile: "+91 9845744699",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/informant_logo.png",
  //   title: "Informant Networks",
  //   description1:
  //     "Cyber security company building UTMs, SDWAN Routers, and Edge Connectivity appliances. We offer network security and secure connectivity solutions to our customers. In addition to the above, we also consult and provide services for organizations looking to improve their security posture.",
  //   website: "informantnetworks.com",
  //   mail: "info@informantnetworks.com",
  //   mobile: "+91 8762362577",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/ipfy-tm-blue-R_logo.png",
  //   title: "IP-FY",
  //   description2:
  //     "We are a purpose-driven brand of technology professionals who understand and know how to apply intellectual property (IP) laws to safeguard your creations of mind which are in the form of ideas, inventions and innovations. ",
  //   description3:
  //     "We can advise and provide consulting services on Patents, Trademarks, IP Due Diligence, IP Litigation and Open Source Legal Compliance.",
  //   website: "https://ip-fy.com/",
  //   mail: "hello@ip-fy.com",
  //   mobile: "+91 9741950883",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/Dattamsh_logo.png",
    title: "Dattamsh Edtech LLP",
    description1:
      "Building talents in the field of Artificial Intelligence and Machine Learning in Belagavi.",
    website: "www.dattamsh.com",
    // mail: "chetana@dattamsh.com",
    // mobile: "+91 9980070640",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/markecha_logo.jpg",
    title: "Markecha",
    description1:
      "We are a leading digital marketing agency helping businesses & professionals build professional online presence, and generate consistent high-quality leads, sales, conversions & customer footfall using strategic digital marketing.",
    description2:
      "We build digital marketing ecosystems that help businesses thrive and scale.",
    description3: "Website | Social Media | Paid Ads | Marketing Funnels",
    website: "markecha.in/",
  },
  // {
  //   image: "images/companies/motocross_logo.jpeg",
  //   title: "Motocross India Private Limited.",
  //   description1:
  //     "Motocross India is an automotive service-tech startup, which aims to become the largest local garage chain in India for two-wheelers.",
  //   website: "www.motocrossindia.in",
  //   mail: "info@motocrossindia.inn",
  //   mobile: "+91 8888222992",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/moveonwheels_logo.jpeg",
  //   title: "Move on wheels (India) Private Limited.",
  //   description1:
  //     "Moveonwheels is a service-tech vehicle rental company. Which currently rents out two-wheelers right from commute to superbikes.",
  //   website: "www.Moveonwheels.in",
  //   mail: "info@moveonwheels.in",
  //   mobile: "+91 8762680082",
  // },
  // {
  //   image: "images/companies/nmc_logo.jpeg",
  //   title: "NoeticMinds Consulting",
  //   description1:
  //     "NoeticMinds Consultins is a Belagavi based Knowledge consulting organisation specialising in:",
  //   listItem1: "Technology & Business Development",
  //   listItem2: "Catering to Small & Medium size businesses worldwide",
  //   listItem3: "Focus areas:eCommerce, ITES, Digital content & Marketing",
  //   website: "NoeticMinds.com",
  //   mail: "contact@Noeticminds.com",
  //   mobile: "+91 7738088565",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/oust_logo.png",
  //   title: "Oust Labs",
  //   description2:
  //     "A mobile platform for last-mile onboarding, training, and engagement in large geographically distributed organizations.",
  //   website: "oustlabs.com",
  //   mail: "oustlabs.com",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/outofbox_logo.png",
    title: "Outofbox Cloud Lab",
    description1:
      "Our mission is to simplify cloud computing so developers and businesses can spend more time creating software that changes the world.",
    website: "outofbox.cloud",
    // mail: "hello@outofbox.cloud",
    // mobile: "+91 9379499565",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/ohms_power.png",
    title: "OHMS Power Solutions",
    description1:
      "Established in 2018, OHMS POWER SOLUTIONS is dedicated to providing high-quality power solutions and power backup to our valued customers. We specialize in a diverse range of products, including Online UPS Systems, Servo Stabilizers, Isolation Transformers, Exide and Amaron Batteries, as well as SMF and Tubular Batteries. Our product offerings also encompass Active Harmonic Filters, Static Transfer Systems, Solar Inverters, and Industrial UPS.",
    website: "ohmspower.com",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  {
    image: "images/companies/rednerds_logo.svg",
    title: "RedNerds",
    description1:
      "RedNerds is ODM division of Evobi Automations Pvt ltd. At Rednerds we research, engineer, design and manufacture electronics products ranging from Smart Toys to Humanoid Robots.",
    website: "therednerds.com/.cloud",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  // {
  //   image: "images/companies/Ezolar_logo.jpg",
  //   title: "Phovoezolar Energy Pvt Ltd (EZOLAR – Easy Solar)",
  //   description2:
  //     "A startup in the Renewable Energy sphere with an aim to digitize the sale of solar projects through our first of its kind marketplace platform (www.ezolar.com). We have executed 6 MWp of solar projects through our EZOLAR platform translating to 25 Crore of Gross Projects Value. We have also developed a fin-tech offering for the financing of solar projects.",
  //   website: "ezolar.com",
  //   mail: "maahir@ezolar.com",
  //   mobile: "+91 9448132233",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/playmantis_logo.png",
  //   title: "Playmantis Studio Private Limited",
  //   description2:
  //     "Playmantis Studio Pvt. Ltd. is aimed to develop high-quality games and experiences for the next generation using the latest experiences and technologies like VR, MR, XR and AR however we might also develop games and experiences which supports native Android and iOS phones, Smart TVs, Windows, Linux, and Mac operating systems.",
  //   website: "playmantis.studio",
  //   mail: "shoot@playmantis.studio",
  //   mobile: "+91 8105631983",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/SenseGiz_logo.jpg",
    title: "SenseGiz Technologies",
    description2:
      "At SenseGiz, they make enterprise and industrial IoT products for sensor-based condition monitoring, security and real-time asset/people tracking, predictive maintenance, in-plant fleet management and perimeter security applications using a combination of proprietary mesh connected hardware, cloud, analytics, and apps. SenseGiz focuses on adding value to customer’s lives every single day by improving productivity and boosting safety & security at the workplace.",
    website: "sensegiz.com",
    // mail: "enquiry@sensegiz.com",
    // mobile: "0831-4202048",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  // {
  //   image: "images/companies/shirish_logo.png",
  //   title: "Shirish Productions",
  //   description2:
  //     "Shirish Productions is a professional Web Development company that was established in the year 2010 by Shirish Chincholimath in Belagavi. Driven by high morals and ethics, they endeavor to be a pioneer in the Information Technology sector/industry and have even served in numerous esteemed projects internationally.",
  //   website: "shirish.productions",
  //   mail: "mail@shirishproductions.com",
  //   mobile: "+91 8073212071",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/ShreshtaIT_Logo.jpg",
  //   title: "Shreshta IT Technologies Pvt. Ltd.",
  //   description1:
  //     "Shreshta IT is an InfoSec company (Blue team) specializing in Network Forensics, Digital Forensics, and Incident Response (DFIR).",
  //   website: "shreshtait.com",
  //   mail: "info@shreshtait.com",
  //   mobile: "+91 9886637820",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  // {
  //   image: "images/companies/starnet_logo.jpg",
  //   title: "StarNet Computer",
  //   description1:
  //     "Distributor | Dealer | Retailer for IT Hardware/Software Products like From DELL | HP | Microsoft -Surface | EPSON | BROTHER | ASUS | CANON Etc.",
  //   website: "starnetBelagavi.com",
  //   mail: "info@starnetBelagavi.com",
  //   mobile: "+91 9986506184",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
  {
    image: "images/companies/Vayavya_Logo.jpg",
    title: "Vayavya Labs Pvt. Ltd.",
    description2:
      "They are pioneers & industry leaders in Hardware-Software Interface™ (HSI™) Tools & Methodologies. We provide embedded software expertise across Automotive, EDA, Semiconductors, and Communications verticals. We hold 11 patents covering EDA/ESL, Embedded SW and SW Automation domains. We have been delighting customers for the past 14 years by our work, our dedication, and cutting edge tools & services.",
    website: "vayavyalabs.com",
    // mail: "info@vayavyalabs.com",
    // mobile: "0831 2400809, +91 80-40901492",
    // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  },
  // {
  //   image: "images/companies/Wetrunk_Logo.png",
  //   title: "WeTrunk IT Solutions Pvt. Ltd.",
  //   description2:
  //     "The area of Focus is Apps and Softwares for Business (MSME) . Currently working on a Data Platform for Cooperatives Credit Societies. We were also Awarded as One of the 100 Most Innovative Startups in Karnataka. “ELEVATE-100 Winner -2017”",
  //   website: "wetrunk.in",
  //   mail: "sachin@wetrunk.in",
  //   mobile: "+91 9448187491",
  //   // sx: "radial-gradient(circle, rgba(179,199,237,1) 0%, rgba(33,143,208,1) 46%, rgba(4,166,170,1) 85%)",
  // },
];

import React from "react";
import Div from "../Div";
// import ContactInfoWidget from "../Widget/ContactInfoWidget";
// import MenuWidget from "../Widget/MenuWidget";
// import Newsletter from "../Widget/Newsletter";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";
// import { useThemeContext } from "../../context/AppProvider";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  // const theme = useThemeContext();
  // const copyrightLinks = [
  //   {
  //     title: "Terms of Use",
  //     href: "/",
  //   },
  //   {
  //     title: "Privacy Policy",
  //     href: "/",
  //   },
  // ];

  const logo = "/images/logo/betca_logo.png";

  return (
    <footer className="cs-fooer" style={{ backgroundColor: "black" }}>
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc={logo}
                  logoAlt="BeTCA"
                  text="Welcome to Belagavi Tech Companies Association ."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              {/* <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div> */}
            </Div>
            <Div className="col-lg-3 col-sm-6">
              {/* <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div> */}
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                {/* <Newsletter
                  title="Subscribe"
                  subtitle="At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit."
                  placeholder="example@gmail.com"
                /> */}
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 BeTCA.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            {/* <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" /> */}
          </Div>
        </Div>
      </Div>
    </footer>
  );
}

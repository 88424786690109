import React, { useEffect } from "react";
import FunFact from "../FunFact";
import Hero from "../Hero";
import Div from "../Div";
import Spacing from "../Spacing";
import { pageTitle } from "../../helper";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";
import { Container, Stack } from "@mui/material";
import HomeContainer from "../HomeContainer";

export default function Home() {
  pageTitle("Home");
  const theme = useThemeContext();

  // FunFact Data
  const funfaceData = [
    {
      title: "Companies Housed",
      factNumber: "35",
    },
    {
      title: "Employees",
      factNumber: "810",
    },
    {
      title: "Crores in Revenue",
      factNumber: "₹340",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        image="/images/logo/betca_logo_poster.png"
        title="Register for Tech Meet Belagavi"
        scrollDownId="#belagavi"
        bgImageUrl="/images/logo/gradient_wallpaper.jpg"
      />
      {/* End Hero Section */}
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: theme.mode ? colors.gray : colors.white }}
      >
        {/* Start FunFact Section */}
        <div className="container">
          <FunFact
            variant="cs-type1"
            title="BeTCA Stats"
            subtitle="Belagavi, IT Hub of the Future."
            data={funfaceData}
          />
        </div>
        {/* End FunFact Section */}
        <div id="belagavi" />
        {/* Start Section */}
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Stack spacing={2}>
            <p
              className="cs-m0 cs-text-allignment"
              style={{ color: theme.mode ? "white" : "black" }}
            >
              BeTCA group is a cluster of companies engaged in Information
              technology (IT) and IT-enabled services (ITeS) which are based in
              Belagavi.
            </p>
            <p
              className="cs-m0 cs-text-allignment"
              style={{ color: theme.mode ? "white" : "black" }}
            >
              The IT sector in Belagavi is very encouraging.
              Considering the human talent around and the blooming companies
              which have chosen Belagavi as their Playground, it was thought of
              collectively coming on a single platform where all the IT
              companies could share their knowledge with the community and also
              collaborate with each other. Human resources management, taxation
              problems, internet connectivity, grooming the upcoming talent in
              colleges are some of the points the BeTCA group will
              emphasize.
            </p>
            <p
              className="cs-m0 cs-text-allignment"
              style={{ color: theme.mode ? "white" : "black" }}
            >
              This site aims to be the one-stop site for your entire search or
              queries related to IT & ITeS firms in Belagavi, IT jobs. The site
              also serves as a medium where experts will attempt to answer your
              queries in the domain of IT & IteS. We also plan to enable all
              employees of the group companies to use this site as a platform to
              share their ideas and gain knowledge by collaborating. By coming
              together, our voices could be heard by the government also.
            </p>
            <p
              className="cs-m0 cs-text-allignment"
              style={{ color: theme.mode ? "white" : "black" }}
            >
              In nutshell, the BeTCA group is a place to Meet, Share &
              Explore.
            </p>
          </Stack>
        </Div>
        {/* Start Container Section */}
        <Spacing lg="150" md="50" />
        <Div className="container">
          <HomeContainer
            title="Why Belagavi?"
            para="The only cosmopolitan city in entire north Karnataka. Large multilingual and multicultural talent pool. Six Engineering and management colleges in the city, largest after Bangalore and Head quarter of state technical university VTU. Equidistant to Hyderabad, Bangalore and Mumbai near proximity to Goa international Airport and IT hub of Pune. Green and clean environment and cool climate through out the year and mainly out of all Seismic sensitive zone."
            to="why_belagavi"
          />
        </Div>
        <Spacing lg="80" md="80" />
        {/* End LogoList Section */}
      </Container>
    </>
  );
}

import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { Container, Stack } from "@mui/material";
import SectionHeading2 from "../SectionHeading2";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";
import MasonryGallery from "../Gallery/MasonryGallery";

export default function AboutPage() {
  const theme = useThemeContext();
  pageTitle("About");

  const funfaceData = [
    {
      title: "Companies Housed",
      factNumber: "35",
    },
    {
      title: "Employees",
      factNumber: "810",
    },
    {
      title: "Crores in Revenue",
      factNumber: "₹340",
    },
  ];

  const color = theme.mode ? "white" : "black";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Container
        maxWidth="xxl"
        sx={{ backgroundColor: theme.mode ? colors.gray : colors.white }}
      >
        <Div className="container">
          <Spacing lg="150" md="80" />
          <Div className="row">
            <Div className="col-xl-11 col-lg-11">
              <SectionHeading
                title="BeTCA"
                subtitle="About us"
                color={color}
              >
                <Spacing lg="30" md="20" />
                <Stack spacing={2}>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    BeTCA is the premier conglomerate of the IT-BPO
                    companies in Belagavi. BeTCA’s patron and associate
                    patron companies are broadly in the business of software
                    development, embedded systems, software services, software
                    products, consulting services, BPO services, e-commerce &
                    web services, engineering services offshoring, and animation
                    and gaming.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    BeTCA was set up in July 2009, at Belagavi to
                    facilitate business and trade in software and services and
                    to encourage the advancement of research in software
                    technology. It is a not-for-profit organization.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    In India and around the world, BeTCA patrons are
                    active participants in the new global economy and are
                    reputed for their innovative business practices, social
                    initiatives, and thrust for foraying into new emerging
                    opportunity areas.
                  </p>
                  <p
                    className="cs-m0 cs-text-allignment"
                    style={{ color: theme.mode ? "white" : "black" }}
                  >
                    BeTCA’s Vision is to create, sustain and escalate
                    Belagavi’s leadership position in the global sourcing IT
                    industry, to grow the market by enabling the industry to tap
                    into emerging opportunity areas and to strengthen the
                    domestic market in India.
                  </p>
                </Stack>
                <Spacing lg="30" md="30" />
                <Div className="cs-separator cs-accent_bg"></Div>
                <Spacing lg="25" md="40" />
              </SectionHeading>
            </Div>
            <Spacing lg="25" md="25" />
            <Div>
              <SectionHeading2
                title="Aims and Objectives"
                subtitle="BeTCA aims to drive the overall growth of the global offshoring market by taking up the role of a strategic advisor to the industry. Our goals include:"
                color={color}
              >
                <ul style={{ color: theme.mode ? "white" : "black" }}>
                  <li>Accelerating trade development efforts</li>
                  <li>Improving talent supply</li>
                  <li>Strengthening local infrastructure</li>
                  <li>
                    Building partnerships and driving operational excellence.
                  </li>
                  <li>Boost the process of Innovation</li>
                  <li>IT workforce development</li>
                  <li>Enhanced cyber security.</li>
                </ul>
                <Spacing lg="25" md="25" />
              </SectionHeading2>
            </Div>
            <Div>
              <SectionHeading2
                subtitle2="BeTCA’s seven fold strategy towards achieving these objectives:"
                color={color}
              >
                <ol style={{ color: theme.mode ? "white" : "black" }}>
                  <li>
                    Strengthen the brand equity of India as a premier global
                    sourcing destination.
                  </li>
                  <li>
                    Partner with the Central and State Governments of India in
                    formulating IT policies and legislation. Partner with global
                    stakeholders for promoting the industry in global markets.
                  </li>
                  <li>
                    Strive for a thought leadership position and deliver
                    world-class research and strategic inputs for the industry
                    and its stakeholders.
                  </li>
                  <li>
                    Expand the quantity and quality of the talent pool in India.
                  </li>
                  <li>
                    Continuous engagement with all patron companies and
                    stakeholders to devise strategies to achieve shared
                    aspirations for the industry and the country.
                  </li>
                  <li>
                    Encourage and facilitate patrons to uphold world class
                    quality and service delivery standards.
                  </li>
                  <li>Aim to uphold Intellectual Property Rights.</li>
                </ol>
                <Spacing lg="25" md="25" />
              </SectionHeading2>
            </Div>
            <Div>
              <SectionHeading2
                subtitle2="BeTCA Patronage"
                color={color}
              ></SectionHeading2>
              <p
                style={{
                  color: theme.mode ? "white" : "black",
                  textAlign: "center",
                }}
              >
                BeTCA welcomes as patrons, companies, and firms that are
                incorporated and/or are registered in Belagavi, which have made
                and will make positive contributions to the IT industry in
                Belagavi and globally.
              </p>
            </Div>
          </Div>
        </Div>
        <Spacing lg="75" md="55" />
        {/* End About Section */}

        {/* Start Gallery Section */}
        <Spacing lg="145" md="80" />
        <MasonryGallery color={theme.mode ? "white" : "black"} />
        {/* End Gallery Section */}

        <Spacing lg="150" md="80" />

        {/* Start Fun Fact Section */}
        <Div className="container">
          <FunFact
            title="BeTCA Stats"
            subtitle="Belagavi, IT Hub of the Future"
            data={funfaceData}
          />
        </Div>
        {/* End Fun Fact Section */}

        <Spacing lg="80" md="80" />
      </Container>
    </>
  );
}

import {
  Typography,
  Grid,
  Box,
  Divider,
  Stack,
  Paper,
  SvgIcon,
} from "@mui/material";
import { LinkIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { useThemeContext } from "../../context/AppProvider";
import { colors } from "../theme/templateColors";
const CompanyCard = (props) => {
  const {
    image,
    website,
    mobile,
    title,
    description1,
    description2,
    description3,
    description4,
    listItem1,
    listItem2,
    listItem3,
    mail,
    sx,
  } = props;
  const theme = useThemeContext();
  const websitelink = "https://" + website;
  const maillink = "mailto:" + mail;
  return (
    <Box
      component={Paper}
      variant="outlined"
      width={520}
      sx={{
        backgroundColor: theme.mode ? "black" : colors.white,
        borderRadius: "28px",
        p: 2,
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11} lg={5}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              sx={{
                px: 1.5,
                borderRadius: "20px",
                background: sx,
                backgroundColor: colors.white,
                height: 150,
                width: 150,
              }}
            >
              <img src={image} alt={title} width={105} />
            </Box>
          </Box>
          <Box
            sx={{
              p: 0.8,
              m: 1.5,
              display: "flex",
              borderRadius: "14px",
              borderColor: theme.mode === true ? colors.white : "",
              boxSizing: "border-box",
              backgroundColor: theme.mode ? colors.gray : colors.white,
            }}
          >
            <SvgIcon
              sx={{
                width: "18px",
                color: theme.mode ? colors.white : colors.black,
              }}
            >
              <LinkIcon />
            </SvgIcon>
            <Box sx={{ mx: 0.5 }}>
              <a
                href={websitelink}
                target="blank"
                style={{
                  fontSize: "12px",
                  color: theme.mode ? colors.white : colors.black,
                }}
              >
                {website}
              </a>
            </Box>
            {mobile != null && (
              <Box>
                <SvgIcon
                  sx={{
                    width: "18px",
                    color: theme.mode ? colors.white : colors.black,
                  }}
                >
                  <PhoneIcon />
                </SvgIcon>
                <Typography
                  variant="caption"
                  color={theme.mode ? colors.white : colors.black}
                  sx={{ mx: 1 }}
                >
                  {mobile}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          component={Paper}
          variant="outlined"
          item
          xs={11}
          lg={7}
          sx={{
            p: 2,
            borderRadius: "14px",
            // borderColor: theme.mode === true ? colors.white : "",
            backgroundColor: theme.mode ? colors.gray : colors.white,
          }}
        >
          <Stack spacing={1}>
            <Box
              component={Paper}
              variant="outlined"
              sx={{
                p: 1,
                borderRadius: "14px",
                mx: 2,
                borderColor: theme.mode === true ? colors.white : "",
                backgroundColor: theme.mode ? colors.gray : colors.white,
              }}
            >
              <Typography
                variant="body1"
                color={theme.mode ? colors.white : colors.black}
                align="center"
                sx={{ fontWeight: 600 }}
              >
                {title}
              </Typography>
            </Box>
            {description1 != null && (
              <Typography
                variant="caption"
                color={theme.mode ? colors.white : colors.black}
                textAlign="center"
              >
                {description1}
              </Typography>
            )}
            {description2 != null && (
              <Typography
                align="center"
                variant="caption"
                color={theme.mode ? colors.white : colors.black}
                sx={{ textAlign: "justify" }}
              >
                {description2}
              </Typography>
            )}
            {description3 != null && (
              <Typography
                align="center"
                variant="caption"
                color={theme.mode ? colors.white : colors.black}
                sx={{ textAlign: "justify" }}
              >
                {description3}
              </Typography>
            )}
            {description4 != null && (
              <Typography
                align="center"
                variant="caption"
                color={theme.mode ? colors.white : colors.black}
                sx={{ textAlign: "justify" }}
              >
                {description4}
              </Typography>
            )}
            {listItem1 != null && (
              <ul>
                <li>
                  <Typography
                    variant="caption"
                    color={theme.mode ? colors.white : colors.black}
                  >
                    {listItem1}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="caption"
                    color={theme.mode ? colors.white : colors.black}
                  >
                    {listItem2}
                  </Typography>
                </li>
                <li>
                  <Typography
                    variant="caption"
                    color={theme.mode ? colors.white : colors.black}
                  >
                    {listItem3}
                  </Typography>
                </li>
              </ul>
            )}
          </Stack>
          <Divider sx={{ mt: 0.5 }}>
            <Paper
              variant="outlined"
              sx={{
                p: 0.5,
                borderRadius: "10px",
                backgroundColor: theme.mode ? colors.gray : colors.white,
              }}
            >
              {mail && (
                <Box display="flex">
                  <SvgIcon
                    sx={{
                      width: "18px",
                      color: theme.mode ? colors.white : colors.black,
                    }}
                  >
                    <EnvelopeIcon />
                  </SvgIcon>
                  <Box sx={{ textAlign: "justify", mx: 1 }}>
                    <a
                      href={maillink}
                      style={{
                        fontSize: "12px",
                        color: theme.mode ? colors.white : colors.black,
                      }}
                    >
                      {mail}
                    </a>
                  </Box>
                </Box>
              )}
            </Paper>
          </Divider>
        </Grid>
      </Grid>
    </Box>
  );
};
export default CompanyCard;

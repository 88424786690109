import { Route, Routes } from "react-router-dom";
import AboutPage from "./components/Pages/AboutPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import PortfolioPage from "./components/Pages/PortfolioPage";
import Layout from "./components/Layout";
import FaqPage from "./components/Pages/FaqPage";
import Companies from "./components/Pages/Companies";
import AboutBelagavi from "./components/Pages/AboutBelagavi";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          {/* <Route path="portfolio" element={<PortfolioPage />} /> */}
          <Route path="contact" element={<ContactPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="companies" element={<Companies />} />
          <Route path="why_belagavi" element={<AboutBelagavi />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
